import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
import { store } from '~/pages/heineken_template/_private/store';
import { useTimeout, useUpdateEffect } from 'react-use';
import TradingPlatformSymbol from '~/modules/symbolPlatform/base/QuoteBodyBase';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { scrollbar2Css } from '~/css/scrollbarCss';
import useMedia from '~/hooks/useMedia';
import QuickFuturesOrderBox from '~/modules/investment-consultant/orderBox/QuickFuturesOrderBox';
import { BiListCheck } from 'react-icons/bi';
export const Weng888_SidePane = memo(function Weng888_SidePane() {
    const [opened, { toggle, close }] = useDisclosure(false);
    const { isPc } = useMedia();
    return (<styleds.container>
      <styleds.header>商品觀察清單</styleds.header>
      <div css={css `
          width: 100%;
          height: calc(100% - ${isPc ? 232 : 80}px);
        `}>
        <SymbolListContent />
      </div>
      {/* {isPc && <OrderButton.Display1 />} */}
      {/* {isPc && <OrderButton.Display22 />} */}
      {/* {isPc && <OrderBox.Display /> }*/}
      {isPc && <styleds.header>國內期貨網頁下單</styleds.header>}
      {isPc && <QuickFuturesOrderBox.Display />}
      {/* <Button onClick={toggle}>網頁下單</Button>
        <Dialog
          css={css`
            width: 316px;
            height: auto;
          `}
          position={{ bottom: 16, left: 8 }}
          opened={opened}
          withCloseButton
          onClose={close}
          size='lg'
          radius='md'
        >
          <OrderBox.Display />
        </Dialog> */}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    background-color: #141721;
    border-right: 2px solid #2b2e38;
    gap: 8px;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 48px;
    border-radius: 4px;
    background-color: #1d1f28;
  `,
};
const SymbolListContent = memo(function SymbolListContent() {
    const charting = useSnapshot(store).charting;
    const { state: watchListState, acts: watchListActs } = useSymbolWatchList('weng888_group_1');
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const watchListSymbol = watchListState.symbolListArray;
    const hadSymbol = watchListSymbol?.includes(charting.symbol);
    useEffect(() => {
        signalrStore2.addQuote(watchListSymbol);
        return () => {
            signalrStore2.removeQuote(watchListSymbol);
        };
    }, [JSON.stringify(watchListSymbol)]);
    const sortData = useSignalr2QuoteSort(watchListSymbol) ?? [];
    const value = signalrHooks2.useQuotes(sortData);
    /** 載入緩衝時間 */
    const [initializeReady, reset] = useTimeout(1250);
    useUpdateEffect(() => {
        reset();
    }, [initializeReady]);
    const container = css `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 4px;
    padding: 2px;
    background-color: #141721;
    border-radius: 4px;
  `;
    const buttonBoard = css `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 30% 70%;
    height: 30px;
    font-size: 14px;
  `;
    return (<div css={container}>
      <div css={buttonBoard}>
        <span css={css `
            ${fill_horizontal_all_center};
          `}>
          <BiListCheck size={28}/>
          加入清單
        </span>
        <Button css={css `
            width: 100%;
            height: 30px;
          `} variant='gradient' gradient={!hadSymbol ? { from: 'indigo', to: 'cyan' } : { from: 'orange', to: 'red' }} onClick={event => {
            if (hadSymbol) {
                watchListActs.removeSymbol(charting.symbol);
            }
            if (!hadSymbol) {
                watchListActs.addSymbol(charting.symbol, 20);
            }
        }}>
          {!hadSymbol ? '加入' : '移除'}
          {charting.symbol} {dictionary[charting.symbol]}
        </Button>
      </div>

      <TradingPlatformSymbol.Header />
      {watchListSymbol?.length === 0 && initializeReady() ? (<LoadCard.emtpty />) : (watchListSymbol?.length === 0 && <LoadCard.Loading />)}

      {value.map((s, index) => {
            return (<TradingPlatformSymbol.Body key={index} quote={s} watchListSymbol={watchListSymbol} watchlistActs={watchListActs}/>);
        })}
    </div>);
});
