import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNamesWithExpiredAt, } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
import Styled from '~/pages/win168/_private/styleds';
import { index_trade } from '~/trades/indicators/asiajye_stock/index_trade';
import { stock_power } from '~/trades/indicators/asiajye_stock/stock_power';
import { stock_trade_original } from '~/trades/indicators/asiajye_stock/stock_trade_original';
import { AsiajyeStock_Sidebar } from './asiajyeStock_Sidebar';
export const asiajyeStock_init = (templateProps) => {
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web_stock',
        'web_stock_pro',
    ]);
    //win168_initStyling()
    //asiajye_initStrategies()
    const initIndicators = [stock_trade_original, index_trade, stock_power];
    useThemeStore.setState({ theme: 'light' });
    templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/win168/login1.png'] }]}/>);
    templateProps.layout.Charting = Styled.Charting;
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        disabledLeftToolbar: true,
        enableVolumeIndicator: true,
        symbol: 'TSEA',
        interval: '15',
        customIndicators: initIndicators,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;

    grid-template-areas:
      'Row1 Row1'
      'Drawer1 Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = (<Preset_Topbar hideIfPcView showLeftBurger showRightBurger/>);
    templateProps.layout.Drawer1 = AsiajyeStock_Sidebar;
};
