import styled from '@emotion/styled';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { Tabs } from '@mantine/core';
import { css } from '@emotion/react';
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { TbSwitchVertical, TbViewfinder } from 'react-icons/tb';
import { HiFire } from 'react-icons/hi';
import { BsGrid1X2 } from 'react-icons/bs';
import { StockGroup } from './component/StockGroup';
import { StockPopular } from './component/StockPopular';
import { StockPick } from './component/StockPick';
import { wu5868_strategiesDayTradeGroup } from './wu5868_initStrategies';
import { store } from '../heineken_template/_private/store';
import { StockSignal } from './component/StockSignal';
export const wu5868_store = proxy({
    tradeDate: '',
});
export const Wu5868_SidePane2 = memo(function Wu5868_SidePane2(props) {
    const charting = useSnapshot(store.charting);
    return (<styleds.container>
      <div css={css `
          ${fill_horizontal_all_center};
          ${jc.spaceEvenly};
          height: 30px;
        `}>
        {wu5868_strategiesDayTradeGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<div css={css `
                border: 1px solid ${isActive ? '#252525' : 'transparent'};
                padding: 0 4px;
                border-radius: 2px;
              `} key={config.displayName} onClick={event => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </div>);
        })}
      </div>
      <Tabs defaultValue='gallery' css={css `
          ${fill_vertical_cross_center};
          gap: 4px;
        `}>
        <Tabs.List css={css `
            width: 100%;
            height: 40px;
          `}>
          <Tabs.Tab value='gallery' icon={<TbViewfinder size='0.8rem'/>}>
            選股
          </Tabs.Tab>
          <Tabs.Tab value='signal' icon={<TbSwitchVertical size='0.8rem'/>}>
            訊號
          </Tabs.Tab>
          <Tabs.Tab value='messages' icon={<HiFire size='0.8rem'/>}>
            熱門股
          </Tabs.Tab>
          <Tabs.Tab value='settings' icon={<BsGrid1X2 size='0.8rem'/>}>
            族群
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='gallery' css={css `
            width: 100%;
            height: calc(100% - 48px);
          `}>
          <StockPick />
        </Tabs.Panel>

        <Tabs.Panel value='signal' css={css `
            width: 100%;
            height: calc(100% - 48px);
          `}>
          <StockSignal />
        </Tabs.Panel>

        <Tabs.Panel value='messages' css={css `
            width: 100%;
            height: calc(100% - 48px);
          `}>
          <StockPopular />
        </Tabs.Panel>

        <Tabs.Panel value='settings' css={css `
            height: calc(100% - 48px);
          `}>
          <useStockPriceChangeDistributionState.Provider>
            <StockGroup />
          </useStockPriceChangeDistributionState.Provider>
        </Tabs.Panel>
      </Tabs>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #fafafa;
    padding: 8px;
    border-right: 1px solid #aaaaaa;
  `,
};
