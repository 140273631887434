import { css } from '@emotion/react';
import { memo } from 'react';
import { ThemeToggle } from '~/components/theme/ThemeToggle';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { store } from '~/pages/heineken_template/_private/store';
export const Good178_TopBar = memo(function Good178_TopBar() {
    const theme = useThemeStore(s => s.theme);
    const topbarColor = theme === 'dark' ? '#262a37' : '#eaeaea';
    return (<div css={css `
        ${fill_horizontal_cross_center};
        justify-content: end;
        background-color: ${topbarColor};
      `}>
      <ThemeToggle onClick={event => {
            store.charting.setThemeMode(useThemeStore.getState().theme);
            store.charting.updateFromThemeMode();
            store.charting.replaceIndicators(store.charting.strategyConfigs[0].indicators);
        }}/>
      <ChartingServerSwitch charting={store.charting}/>
      <UserAvatarAsDialogButton />
    </div>);
});
