import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { DataChart } from '~/pages/big3_stock/big3Stock_SidePane2';
import { useStockPriceChangeDistributionState } from '~/pages/futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
export const StockGroup = memo(function StockGroup() {
    const { state, acts } = useStockPriceChangeDistributionState.useContainer();
    const formattedData = state.allCategories?.map(s => {
        const changePercent = (state?.avg_change_percent?.get(s) || 0).toFixed(2);
        return { name: s, value: changePercent };
    }) || [];
    const sortData = formattedData.sort((a, b) => Number(b.value) - Number(a.value));
    return (<div css={fill_vertical_cross_center}>
      <styleds.chartContent>
        <DataChart />
      </styleds.chartContent>

      <styleds.stockGroupContent>
        {sortData?.map(s => (<styleds.card key={s.name} css={css `
              background-color: ${barFill(Number(s.value))}99;
            `}>
            <div>{s.name}</div>
            <div>{s.value}%</div>
          </styleds.card>))}
        {/* <Query /> */}
      </styleds.stockGroupContent>
    </div>);
});
const styleds = {
    container: styled.div ``,
    chartContent: styled.div `
    width: 300px;
    height: 236px;
    border-radius: 5px;
    padding: 4px;
  `,
    stockGroupContent: styled.div `
    ${scrollbar2Css};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    height: calc(100% - 240px);
    gap: 4px;
    padding: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #888;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      border-radius: 5px;
      background-color: #ccc;
    }
  `,
    card: styled.div `
    ${fill_vertical_all_center};
    height: 56px;
    width: 48%;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 14px;
  `,
};
export const barFill = (percentage) => {
    if (percentage > 0 && percentage < 2)
        return classesFill.horizontalBarUpFill1;
    else if (percentage >= 2 && percentage < 4)
        return classesFill.horizontalBarUpFill2;
    else if (percentage >= 4 && percentage < 6)
        return classesFill.horizontalBarUpFill3;
    else if (percentage >= 6 && percentage < 8)
        return classesFill.horizontalBarUpFill4;
    else if (percentage >= 8 && percentage < 10)
        return classesFill.horizontalBarUpFill5;
    else if (percentage > -2 && percentage < 0)
        return classesFill.horizontalBarDnFill1;
    else if (percentage >= -4 && percentage < -2)
        return classesFill.horizontalBarDnFill2;
    else if (percentage >= -6 && percentage < -4)
        return classesFill.horizontalBarDnFill3;
    else if (percentage >= -8 && percentage < -6)
        return classesFill.horizontalBarDnFill4;
    else if (percentage >= -10 && percentage < -8)
        return classesFill.horizontalBarDnFill5;
    else
        return classesFill.horizontalBarDefaultFill;
};
const classesFill = {
    horizontalBarDefaultFill: '#bbbbbb',
    horizontalBarUpFill1: '#FFCDD2',
    horizontalBarUpFill2: '#EF9A9A',
    horizontalBarUpFill3: '#E57373',
    horizontalBarUpFill4: '#EF5350',
    horizontalBarUpFill5: '#F44336',
    horizontalBarDnFill1: '#C8E6C9',
    horizontalBarDnFill2: '#A5D6A7',
    horizontalBarDnFill3: '#81C784',
    horizontalBarDnFill4: '#66BB6A',
    horizontalBarDnFill5: '#4CAF50',
};
