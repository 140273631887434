import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
export const wu5868_signal2 = createIndicator({
    id: 'wu5868-signal2',
    displayName: '30分切入點',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const std = this._input(0);
            const stoplossPrecent = 0.94;
            const interval = context.symbol.period;
            const position = context.new_var();
            const entryPrice = context.new_var();
            const entriesToday = context.new_var();
            const close = this.ohlc.closeArray;
            const open = this.ohlc.openArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const volume = context.new_var(this.PineJS.Std.volume(context));
            const datetime = dayAPI(this.PineJS.Std.time(context));
            const lowest = this.PineJS.Std.lowest(low, 4, this._context);
            const stoplossLowest = this.PineJS.Std.lowest(low, std, this._context);
            const openTime = datetime.hour() === 9 && datetime.minute() === 0;
            const closeTime = datetime.hour() === 13 && datetime.minute() === 30;
            const dayHigh = context.new_var();
            const lastClose = context.new_var();
            const exitPrice = context.new_var();
            const entryHigh = context.new_var();
            const length = 60;
            const stds = 2;
            const bband = this.bollingerBand(close, length, stds);
            position.get(1);
            entriesToday.get(5);
            entryPrice.get(5);
            close.get(5);
            open.get(5);
            high.get(5);
            low.get(5);
            volume.get(5);
            exitPrice.get(5);
            let buy_icon1 = NaN;
            let buy_icon2 = NaN;
            let buy_icon3 = NaN;
            let buy_exit_icon1 = NaN;
            if (openTime) {
                dayHigh.set(high.get(0));
            }
            if (closeTime) {
                lastClose.set(close.get(0));
            }
            //
            if (high.get(0) > dayHigh.get(0)) {
                dayHigh.set(high.get(0));
            }
            //大於昨收 收紅 大於中線 前次收盤小於中線
            const longEntry = close.get(0) > lastClose.get(0) &&
                close.get(0) > open.get(0) &&
                close.get(0) > bband.mid &&
                close.get(1) < bband.mid &&
                datetime.hour() < 13 &&
                datetime.hour() >= 9;
            //近四根低點小於作收 大於昨收 大於中線
            const longEntry2 = lowest < lastClose.get(0) &&
                close.get(0) >= lastClose.get(0) &&
                close.get(0) > bband.mid &&
                datetime.hour() < 13 &&
                datetime.hour() >= 9;
            //強勢開高走高 大於上線 高=當日最高 收紅
            const longEntry3 = close.get(0) > lastClose.get(0) &&
                close.get(0) > bband.top &&
                close.get(0) > open.get(0) &&
                high.get(0) >= dayHigh.get(0) &&
                datetime.hour() <= 10 &&
                datetime.minute() >= 5;
            // ---------------------------------------- Entry ----------------------------------------
            if ((interval === '30' && position.get(0) === 0) ||
                (interval === '30' && isNaN(position.get(0)))) {
                if (longEntry)
                    position.set(1);
                //if (longEntry2) position.set(2)
                //if (longEntry3) position.set(3)
            }
            if (position.get(0) === 1 && position.get(1) !== 1) {
                buy_icon1 = 1;
                exitPrice.set(close.get(0) * stoplossPrecent);
                entryPrice.set(close.get(0));
                entryHigh.set(high.get(0));
            }
            if (position.get(0) === 2 && position.get(1) !== 2) {
                buy_icon2 = 1;
                exitPrice.set(close.get(0) * stoplossPrecent);
                entryPrice.set(close.get(0));
                entryHigh.set(high.get(0));
            }
            if (position.get(0) === 3 && position.get(1) !== 3) {
                buy_icon3 = 1;
                exitPrice.set(close.get(0) * stoplossPrecent);
                entryPrice.set(close.get(0));
                entryHigh.set(high.get(0));
            }
            // ---------------------------------------- Exit ----------------------------------------
            const stoplossLine = this._context.new_var();
            stoplossLine.get(5);
            if (position.get(0) > 0 && position.get(1) > 0) {
                if (high.get(0) > entryHigh.get(0)) {
                    entryHigh.set(high.get(0));
                }
                if (close < exitPrice.get(0)) {
                    position.set(0);
                    exitPrice.set(NaN);
                    stoplossLine.set(NaN);
                    entryHigh.set(NaN);
                    buy_exit_icon1 = 1;
                }
                if (entryHigh.get(0) > entryPrice.get(0) * 1.05) {
                    stoplossLine.set(stoplossLowest);
                }
                if (entryHigh.get(0) > entryPrice.get(0) * 1.05 && close.get(0) < stoplossLine.get(1)) {
                    position.set(0);
                    exitPrice.set(NaN);
                    stoplossLine.set(NaN);
                    entryHigh.set(NaN);
                    buy_exit_icon1 = 1;
                }
            }
            return [
                buy_icon1,
                buy_icon2,
                buy_icon3,
                buy_exit_icon1,
                exitPrice.get(0),
                stoplossLine.get(0),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'entryLongFlag1',
                type: 'chars',
            },
            {
                id: 'entryLongFlag2',
                type: 'chars',
            },
            {
                id: 'entryLongFlag3',
                type: 'chars',
            },
            {
                id: 'exitLongFlag1',
                type: 'chars',
            },
            { id: 'exitPrice', type: 'line' },
            { id: 'stoplossPrice', type: 'line' },
        ],
        defaults: {
            styles: {
                entryLongFlag1: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                entryLongFlag2: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                entryLongFlag3: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                exitLongFlag1: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                exitPrice: {
                    linestyle: 1,
                    linewidth: 2,
                    plottype: 7,
                    transparency: 0,
                    visible: true,
                    color: '#ff8800',
                },
                stoplossPrice: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 7,
                    transparency: 0,
                    visible: true,
                    color: '#df484c',
                },
            },
            inputs: {
                std: 44,
            },
        },
        styles: {
            entryLongFlag1: {
                title: '多單進場1',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '多方醞釀',
            },
            entryLongFlag2: {
                title: '多單進場2',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '站回日內多方',
            },
            entryLongFlag3: {
                title: '多單進場3',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '強勢買進',
            },
            exitLongFlag1: {
                title: '多單出場',
                isHidden: false,
                location: 'AboveBar',
                char: 'X',
                size: 'small',
                text: '多單出場',
            },
        },
        inputs: [
            {
                id: 'std',
                name: '低點防守',
                defval: 44,
                type: 'float',
                min: 1,
                max: 100,
                step: 1,
            },
        ],
    },
});
